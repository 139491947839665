import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CardGroup } from "react-bootstrap"
import SingleCard from "./single-card"

export default function FeaturedBlogList() {
  const data = useStaticQuery(graphql`
    query FeaturedBlogListQuery {
      blog: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { eq: "featured" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(fromNow: true)
              title
              author
              description
              tags
              featuredImage {
                relativePath
                absolutePath
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(pruneLength: 100)
            id
          }
        }
      }
    }
  `)
  const allPosts = data.blog.edges

  return (
    <div>
      <h2
        style={{
          color: "#CF5C36",
          fontFamily: "Major Mono Display",
          fontSize: "180%",
          padding: "30px 0px",
        }}
      >
        <span style={{ backgroundColor: "#EFC88B", padding: "10px 10px" }}>
          Tech And Software
        </span>
      </h2>
      <CardGroup>
        {allPosts
          .filter(edge => edge.node.frontmatter.tags.includes("technology"))
          .map(({ node }) => (
            < SingleCard key={node.id} data={node} />
          ))}
      </CardGroup>
      <h2
        style={{
          color: "#CF5C36",
          fontFamily: "Major Mono Display",
          fontSize: "180%",
          padding: "30px 0px",
        }}
      >
        <span style={{ backgroundColor: "#EFC88B", padding: "10px 10px" }}>
          Travel And Art
        </span>
      </h2>
      <CardGroup>
        {allPosts
          .filter(edge => edge.node.frontmatter.tags.includes("travel"))
          .map(({ node }) => (
            <SingleCard key={node.id} data={node} />
          ))}
      </CardGroup>
    </div>
  )
}

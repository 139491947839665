import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedBlogList from "../components/featured-posts"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div
      style={{
        textAlign: "center",
        fontFamily: "Homemade Apple",
        padding: "20px",
      }}
    >
      <h1 style={{ color: "#EFC88B", paddingBottom: "10px" }}>Melcow</h1>
      <div style={{ color: "lightseagreen", fontSize: "30px" }}>
        A foray of Tech&ensp;Learnings&ensp;and&ensp;Art&ensp;Outlooks
      </div>
    </div>
    <FeaturedBlogList />
  </Layout>
)

export default IndexPage
